* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
  background: black;
  color: white;
  min-height: 850px;
}
