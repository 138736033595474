.Staking {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 1em; */
  max-width: 1200px; /* Ancho máximo para la versión de escritorio */
  margin: 0 auto; /* Centrar el contenido */
}

.Staking h1 {
  margin-top: 1.2em;
  font-size: 1.5em; /* Ajustar tamaño para móviles */
}

.icon {
  height: 14em;
  margin-top: 2em;
  opacity: 0.9;
  max-width: 100%;
}

.inputDiv {
  display: grid;
  grid-template-columns: 0 auto 0; /* Ajusta el ancho de la columna del checkbox según sea necesario */
  gap: 0.5em; /* Espacio entre el input y el checkbox */
  align-items: center;
  padding: 1em;
  width: 80%;
}

.input {
  height: 2.5em;
  width: 100%;
  margin: auto;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(23, 60, 139, 0.301);
}

.maxLabel {
  display: flex;
  align-items: center;
  font-size: 1em;
  color: white;
}

.maxCheckbox {
  margin-left: 0.5em;
}

.totals {
  margin-top: 2em;
  height: auto;
}

.goMax {
  cursor: pointer;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1em; /* Espacio entre los botones */
  margin-top: 1em;
}

.stakeButton,
.unstakeButton,
.withdrawRewardsButton,
.reinvestRewardsButton {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1em;
  border: white solid 0.05em;
  border-radius: 0.5em;
  height: 2.5em;
  flex: 1; /* Hacer que los botones ocupen el mismo ancho */
  max-width: 15em;
  cursor: pointer;
}

.stakeButton:hover:not(:disabled),
.unstakeButton:hover:not(:disabled),
.withdrawRewardsButton:hover:not(:disabled),
.reinvestRewardsButton:hover:not(:disabled) {
  background-color: #2675cf;
}

.unstakeButton:hover:not(:disabled) {
  background-color: #1c375f;
}

.stakeButton:focus,
.unstakeButton:focus,
.withdrawRewardsButton:focus,
.reinvestRewardsButton:focus {
  outline: none;
}

.stakeButton:disabled,
.unstakeButton:disabled,
.withdrawRewardsButton:disabled,
.reinvestRewardsButton:disabled {
  cursor: not-allowed;
  border: 0.05em solid red;
  background-color: rgba(128, 128, 128, 0.3);
  color: rgba(255, 255, 255, 0.5);
}

.stakeIcon {
  height: 1.5em;
  position: relative;
  top: 0.1em;
  right: 0.5em;
}

.stakeButton p,
.unstakeButton p,
.withdrawRewardsButton p,
.reinvestRewardsButton p {
  display: inline;
  position: relative;
  bottom: 0.2em;
}

.bottomBar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #2c3e50;
  color: white;
  text-align: center;
  padding: 0.5em 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
  font-size: 0.8em; /* Ajustar tamaño para móviles */
}

.bottomBarContent {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.bottomBarContent span {
  margin: 0.5em 0.5em;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  p {
    font-size: calc(13px + 0.390625vw);
  }

  .maxLabel {
    font-size: calc(10px + 0.390625vw);
  }

  .inputDiv {
    padding: 1em;
    flex-direction: column;
  }

  .input {
    width: 100%;
  }

  .buttonGroup {
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }

  .stakeButton,
  .unstakeButton,
  .withdrawRewardsButton,
  .reinvestRewardsButton {
    width: 100%;
  }

  .totals {
    padding-bottom: 2em;
  }

  .totals h4 {
    font-size: 0.8em;
  }

  .bottomBar {
    font-size: 0.7em;
  }
}
