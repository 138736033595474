.Grid {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: auto auto 48em auto auto;
  grid-template-rows: 5em auto 5em;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
}

.Child {
  background: linear-gradient(
      302deg,
      rgb(194 103 36) 0%,
      rgb(73, 35, 10) 42%,
      rgb(95, 50, 8) 100%
    ),
    linear-gradient(to right, rgb(235, 74, 15), rgb(141, 49, 16));
  border: 0.3em solid rgba(235, 103, 15, 0.514);
  background-repeat: no-repeat;
  background-origin: padding-box, border-box;
  border-radius: 2.5em;
  grid-column: 3;
  grid-row: 2;
  text-align: center;
  color: white;
  -webkit-box-shadow: 0px 0px 800px 20px rgba(19, 75, 99, 0.44);
  box-shadow: 0px 0px 800px 20px rgba(19, 75, 99, 0.44);
}

.curtain {
  background-color: rgba(0, 0, 0, 0.466);
  position: static;
  height: 99em;
  width: auto;
  grid-column-start: 1;
  grid-column-end: 6;
  z-index: 2;
  filter: blur(8px);
  -webkit-backdrop-filter: blur(1.5em);
  backdrop-filter: blur(1.5em);
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .Grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1em auto 1em;
  }

  .Child {
    grid-column: 1;
    grid-row: 2;
    border-radius: 1em;
    -webkit-box-shadow: 0px 0px 50px 10px rgba(19, 75, 99, 0.44);
    box-shadow: 0px 0px 50px 10px rgba(19, 75, 99, 0.44);
  }
}
